import React, { useEffect, useRef } from 'react'

// styles import
import styles from './review.module.css'

import Card from '../../../organisms/cards/review-card/ReviewCard.organism'
// import Pagination from '../../shared/pagination/Pagination'
import ReviewData from '../../../../repository/data/review-data'

const Review: React.FC = () => {
  // const [index, setIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const scrollDirectionRef = useRef<number>(1)

  useEffect(() => {
    const container = containerRef.current
    let scrollInterval: NodeJS.Timer
    const scrollSpeed = 25 // Smaller = faster

    function startScrolling() {
      if (!container) {
        return
      }

      scrollInterval = setInterval(() => {
        if (container.scrollTop === 0) {
          scrollDirectionRef.current = 1
        } else if (container.scrollTop >= container.scrollHeight - container.offsetHeight) {
          scrollDirectionRef.current = -1
        }

        container.scrollTop += scrollDirectionRef.current
      }, scrollSpeed)
    }

    function stopScrolling() {
      clearInterval(scrollInterval)
    }

    startScrolling()

    return () => {
      stopScrolling()
    }
  }, [])

  return (
    <section className={styles.wrapper}>
      <div style={{ textAlign: 'center' }}>
        <div className={styles.div__styles}>
          <h2 className={styles.heading}>Here is what our students experienced</h2>
          <p className={styles.p__styles}>
            Acquire industry standard skills for free and land you dream job
          </p>
        </div>
        {/* <div className={styles.pagination__wrapper}>
          <Pagination activeIndex={index} setActiveIndex={setIndex}>
            {reviewData.map((data, index) => {
              return (
                <Card
                  key={index}
                  description={data.description}
                  image={data.image}
                  name={`${data.name} ${index}`}
                  courseName={data.courseName}
                  review={data.review}
                />
              )
            })}
          </Pagination>
        </div> */}
        <div className={styles.cont}>
          <div className={styles.shadow}></div>
          <div className={`${styles.shadow} ${styles.shadowTop}`}></div>

          <div
            className={styles.reviews__wrapper}
            ref={containerRef}
            onScroll={(e) => e.preventDefault()}
          >
            <div className={styles.reviews}>
              {ReviewData.map((data, index) => {
                return (
                  <Card
                    key={index}
                    description={data.description}
                    image={data.image}
                    name={data.name}
                    courseName={data.courseName}
                    review={data.review}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Review
