import React from 'react'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import styles from './card.module.css'

type Props = {
  description: string
  image: string
  name: string
  courseName: string
  review: number
}

const Card: React.FC<Props> = ({ description, image, name, courseName }) => {
  return (
    <div className={styles.container}>
      <p className={styles.review__text}>{description}</p>
      <img
        src={image}
        alt={name}
        style={{
          width: '56px',
          height: '56px',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
      <h3 className={styles.name}> {name}</h3>
      <p className={styles.course__name}> {courseName}</p>
      <span className={styles.review}>
        {Array(5)
          .fill(0)
          .map((_ie, index) => {
            return (
              <IconsRepository.StarIcon
                color='var(--foundation-secondary-500)'
                key={index}
                size={24}
              />
            )
          })}
      </span>
    </div>
  )
}

export default Card
