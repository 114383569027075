import React from 'react'
import styles from './modals.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import formatNumber from '../../../../lib/utils/formatNumber'

interface ConfirmProps {
  makePayment: () => void
  back: () => void
  closeModal: () => void
  phone: string
  price: number
  course: string
}

const Confirmmodal: React.FC<ConfirmProps> = ({
  makePayment,
  back,
  closeModal,
  phone,
  price,
  course,
}) => {
  return (
    <div className={styles.body} onClick={(e) => e.stopPropagation()}>
      <span className={styles.close} style={{ marginBottom: '6rem' }} onClick={closeModal}>
        <IconsRepository.CloseIcon width={19} height={19} />
      </span>
      <div className={styles.content}>
        <div className={styles.text}>
          <h2>Confirm Payment</h2>
          <p>
            Confirm the checkout of:{' '}
            <span style={{ color: 'var(--platform-secondary-700)', fontWeight: 600 }}>
              {formatNumber(price)}XAF
            </span>
            <br />
            From the account:{' '}
            <span style={{ fontWeight: 600 }}>
              {phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')}
            </span>
            <br />
            to enroll into the course: <span style={{ fontWeight: 600 }}>{course}</span>
          </p>
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          fontSize='16px'
          width=' 360px'
          height='48px'
          text={'Continue'}
          className={styles.btn2}
          onClick={makePayment}
        />
        <Button
          type={ButtonTypes.SECONDARY}
          fontSize='16px'
          width='fit-content'
          height='fit-content'
          text={'Back'}
          iconDir={IconDirection.LEFT}
          icon={<IconsRepository.ArrowLeftIcon />}
          className={styles.backBtn}
          onClick={back}
        />
      </div>
    </div>
  )
}

export default Confirmmodal
