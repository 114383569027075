import React from 'react'

import { courseBackground } from '../../../../repository/assets/videos'

import styles from './intro.module.css'
import { CourseEntityType } from '../../../../types/models'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../lib/hooks'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { courseCover as courseBanner } from '../../../../repository/assets'
import formatNumber from '../../../../lib/utils/formatNumber'

const CourseIntro: React.FC<CourseEntityType> = ({
  id,
  name,
  author,
  language,
  lessons,
  cover,
  categories,
  price = 0,
}) => {
  const { user } = useAppSelector((state) => state.authentication)
  const width = useWindowSize().width
  const [courseCover, setCover] = React.useState(cover?.url || courseBanner)

  return (
    <section className={styles.body}>
      <video
        loop
        muted
        autoPlay={!cover?.url}
        className={styles.video}
        poster={courseCover}
        onError={() => setCover(courseBanner)}
      >
        <source src={courseBackground} type={'video/webm'} />
      </video>
      <h1>{name}</h1>
      <p>by {author?.name}</p>
      <div className={styles.details}>
        <div>
          <span>
            <IconsRepository.Category size={width < 500 ? 15 : 20} />
            {categories
              ? categories
                  .map((c) => c.name)
                  .reduce((p, cat) => `${p} ${cat},`, '')
                  ?.slice(0, -1)
              : ''}
          </span>
          <span>
            <IconsRepository.PlayCircleFilledIcon size={20} /> {lessons?.length} lessons
          </span>
        </div>
        <span>
          <IconsRepository.LanguageIcon size={20} />
          {language}
        </span>
      </div>
      <div className={styles.actions}>
        <Link
          to={user ? `/course-preview/${id}` : '/auth/register'}
          onClick={() => {
            if (!user) {
              localStorage.setItem('course', String(id))
            }
          }}
        >
          <Button
            type={ButtonTypes.PRIMARY}
            text={
              <span className='price_span'>
                <span>Enroll Now for</span>
                {formatNumber(price)}frs
              </span>
            }
            fontSize='16px'
          />
        </Link>
        {/* <Button
          type={ButtonTypes.PRIMARY}
          text={'Watch Trailer'}
          iconDir={IconDirection.RIGHT}
          icon={<IconsRepository.PlayCircleFilledIcon />}
          className={styles.btn}
          fontSize='16px'
          onClick={() => {
            const section =
              // eslint-disable-next-line
              document.getElementById('about')!.getBoundingClientRect()?.top + window.scrollY || 0 // @ts-ignore
            window.scrollTo({
              top: section,
              behavior: 'smooth',
            })
          }}
        /> */}
      </div>
    </section>
  )
}

export default CourseIntro
