import axios, { AxiosResponse } from 'axios'

import { APPCONFIGS } from '../configs/app-configs'
import { LocalStorage } from '../services/storage/Local.storage'

export enum APIStatus {
  IDLE,
  PENDING,
  REJECTED,
  FULFILLED,
}

export type APIError = {
  response: {
    data: {
      error: string
    }
    status: number
  }
}

export type API<DataType> = {
  status: APIStatus
  error?: APIError
  data?: DataType
}

export const InternalError = {
  error: 'Internal error occurred during the request.',
  code: -500,
}

export const onFulfilledRequest = (response: AxiosResponse) => response

const logout = () => {
  LocalStorage.removeCurrentUser()
  localStorage.removeItem('email_tracker')

  location.assign('/auth/login')
}

// eslint-disable-next-line
export const onRejectedResponse = (_error: any): any => {
  const {
    data: { error },
    status,
  } = _error.response
  if (status === 401 && error.includes('rejected token')) {
    // LogOut Here
    logout()
  }

  return Promise.reject(_error)
}

export const publicRequest = axios.create({
  baseURL: `${APPCONFIGS.BASE_URL}/api`,
  // baseURL: 'http://localhost:8000/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
  },
})

export enum PUSHER_EVENTS {
  'PAYMENT_COMPLETE' = 'PAYMENT_COMPLETE',
  'PAYMENT_FAILED' = 'PAYMENT_FAILED',
}

export enum PUSHER_CHANNELS {
  'course' = 'COURSE',
}

publicRequest.interceptors.response.use(onFulfilledRequest, onRejectedResponse)
