import React from 'react'
import styles from './peek.module.css'

import {
  language as languageIcon,
  lessons as lessonIcon,
  Vector,
} from '../../../../repository/assets/svg'
import { ButtonTypes } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import { CourseEntityType } from '../../../../types/models'
import { Link } from 'react-router-dom'
import { appPaths } from '../../../../constants/app-paths'
import formatNumber from '../../../../lib/utils/formatNumber'

const Peek: React.FC<CourseEntityType> = ({
  categories,
  lessons,
  language,
  description,
  price,
}) => {
  return (
    <div className={styles.rootContenaire}>
      <div className={styles.rootGrid}>
        <div className={styles.gridItem}>
          <p className={styles.peek}>Take a peek at what you will get inside the course</p>
          <p className={styles.description}>
            We help you grow yours skills and knowledge with the help of meticulously crafted
            courses that will arm your the job world ahead. Enroll now for free and join the
            adventure.
          </p>
          <Link to={appPaths.REGISTER}>
            <Button
              text={
                <span className='price_span'>
                  <span>Enroll Now for</span>
                  {formatNumber(price || 0)}frs
                </span>
              }
              type={ButtonTypes.PRIMARY}
              fontSize='16px'
              className={styles.button}
            />
          </Link>
        </div>

        <div className={styles.gridItem2}>
          <p className={styles.course}>Course Content</p>
          <div className={styles.textIcon}>
            <img src={Vector} className={styles.Vector} />
            <p className={styles.category}>
              {' '}
              Category:{' '}
              {categories?.map((cat, i, arr) => {
                return `${cat.name}${i < arr.length - 1 ? ',' : ''} `
              })}
            </p>
          </div>
          <div className={styles.textIcon}>
            <img src={lessonIcon} className={styles.Vector} />
            <p className={styles.category}> Lessons: {lessons?.length || 0} Lessons </p>
          </div>
          <div className={styles.textIcon}>
            <img src={languageIcon} className={styles.Vector} />
            <p className={styles.lang}>{language}</p>
          </div>
        </div>

        <div className={styles.gridItem3}>
          <p className={styles.courseDescription}> Description </p>
          <p className={styles.inThisCourse}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Peek
