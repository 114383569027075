import React, { useCallback, useEffect, useState } from 'react'
import DashboardFooter from '../../components/organisms/footer/DashboardFooter.organism'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import Curriculum from '../../components/templates/course-preview/curriculum/Curriculum.template'
import SideBar from '../../components/templates/course-preview/sidebar/Sidebar.template'
import { defaultImage } from '../../repository/assets'

import styles from './coursepreview.module.css'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { enrollCourseThunk, singleCourseThunk } from '../../features/course/thunks/course.thunk'
import { useNavigate, useParams } from 'react-router-dom'
import { setCurrentCourse } from '../../features/authentication/authenticationSlice'
import Paymentmodal from '../../components/templates/course-preview/modals/Paymentmodal.template'
import Modal from '../../components/organisms/modal/Modal.organism'
import Successmodal from '../../components/templates/course-preview/modals/Successmodal.template'
import Loader from '../../components/atoms/loader/Loader'
import Confirmmodal from '../../components/templates/course-preview/modals/Confirmmodal.template'
import axiosInstance from '../../lib/hooks/axios'
import { LocalStorage } from '../../services/storage/Local.storage'
import usePusher from '../../lib/hooks/usePusher'
import { PUSHER_CHANNELS, PUSHER_EVENTS } from '../../constants/configs'
import Popup from '../../components/organisms/popup/Popup.organism'
import Pendingmodal from '../../components/templates/course-preview/modals/Pendingmodal.template'

type modals = 'payment' | 'success' | 'confirm' | 'pending' | null

const CoursePreview = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { singleCourse, isLoading } = useAppSelector((state) => state.course)
  const { currentCourse, user } = useAppSelector((state) => state.authentication)
  const dispatch = useAppDispatch()
  const [currentModal, setCurrentModal] = useState<modals>(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    phone: '',
  })

  usePusher({
    channel: PUSHER_CHANNELS.course,
    event: PUSHER_EVENTS.PAYMENT_COMPLETE,
    userId: `${user?.id}`,
    type: 'private',
    listener: () => {
      setLoading(false)
      setCurrentModal('success')
    },
  })

  usePusher({
    channel: PUSHER_CHANNELS.course,
    event: PUSHER_EVENTS.PAYMENT_FAILED,
    userId: `${user?.id}`,
    type: 'private',
    listener: () => {
      setLoading(false)
      setCurrentModal(null)
      setError(true)
    },
  })

  useEffect(() => {
    if (!user) {
      navigate(`/course-details/${id}`)
      return
    }
    if (!singleCourse || singleCourse.id !== id) {
      dispatch(singleCourseThunk(id as string))
    }
    if (!currentCourse || id !== currentCourse) {
      dispatch(setCurrentCourse(id))
    }
  }, [id])

  const enrollCourse = useCallback(async () => {
    if (user && id) {
      setLoading(true)
      const { email } = user
      await dispatch(enrollCourseThunk({ id, email })).then(() => {
        if (
          singleCourse.lessons !== undefined &&
          singleCourse.lessons.length > 0 &&
          singleCourse.lessons[0].subLessons !== undefined &&
          singleCourse.lessons[0].subLessons.length > 0
        ) {
          navigate(
            `/full-course/${singleCourse.id}/${singleCourse.lessons[0].id}/${singleCourse.lessons[0].subLessons[0].id}`,
          )
        }
      })
      setLoading(false)
    }
  }, [singleCourse, user, id])

  const startLesson = () => {
    setCurrentModal(null)
    if (
      singleCourse.lessons !== undefined &&
      singleCourse.lessons.length > 0 &&
      singleCourse.lessons[0].subLessons !== undefined &&
      singleCourse.lessons[0].subLessons.length > 0
    ) {
      navigate(
        `/full-course/${singleCourse.id}/${singleCourse.lessons[0].id}/${singleCourse.lessons[0].subLessons[0].id}`,
      )
    }
  }

  const makePayment = async () => {
    setCurrentModal(null)
    setLoading(true)
    try {
      const res = await axiosInstance.post(
        '/courses/payment',
        {
          courseId: singleCourse.id,
          accountNumber: `+237${formData.phone}`,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      console.log(res)
      setLoading(false)
      setCurrentModal('pending')
    } catch (e) {
      setError(true)
      console.error(e)
      setLoading(false)
    }
  }

  const enroll = () => {
    if (singleCourse.price) {
      setCurrentModal('payment')
      return
    }

    enrollCourse()
  }

  return (
    <>
      <NavbarLoggedin padding='32px 3.5rem' userImage={defaultImage} />
      {loading || isLoading ? <Loader /> : null}
      <Popup
        toggleActive={setError}
        active={error}
        title='error'
        msg={'Payment Failed. Please Try Again'}
      />
      <main className={styles.body}>
        <SideBar key={id} openModal={() => enroll()} />
        <Curriculum openModal={() => enroll()} />
      </main>
      <Modal isOpen={currentModal === 'payment'} closeModal={() => setCurrentModal(null)}>
        <Paymentmodal
          formData={formData}
          setFormData={setFormData}
          closeModal={() => setCurrentModal(null)}
          next={() => {
            setCurrentModal('confirm')
          }}
        />
      </Modal>
      <Modal isOpen={currentModal === 'confirm'} closeModal={() => setCurrentModal(null)}>
        <Confirmmodal
          course={singleCourse.name || ''}
          phone={formData.phone}
          price={singleCourse.price || 0}
          makePayment={makePayment}
          back={() => {
            setCurrentModal('payment')
          }}
          closeModal={() => setCurrentModal(null)}
        />
      </Modal>
      <Modal isOpen={currentModal === 'pending'} closeModal={() => console.log(null)}>
        <Pendingmodal />
      </Modal>
      <Modal isOpen={currentModal === 'success'} closeModal={() => console.log(null)}>
        <Successmodal startLesson={startLesson} />
      </Modal>
      <DashboardFooter />
    </>
  )
}

export default CoursePreview
