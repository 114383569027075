import Pusher from 'pusher-js'
import { APPCONFIGS } from '../../configs/app-configs'

class PusherManager {
  private pusher: Pusher

  constructor() {
    this.pusher = this.init()
  }

  init() {
    const pusher = new Pusher(APPCONFIGS.PUSHER_KEY || '', {
      cluster: 'sa1',
      // wsHost: APPCONFIGS.PUSHER_HOST,
      // wsPort: Number(APPCONFIGS.PUSHER_PORT),
      // forceTLS: false,
      // enableStats: false,
      // enabledTransports: ['ws'],
      // disabledTransports: ['wss'],
    })

    Pusher.logToConsole = true

    console.log(pusher)

    return pusher
  }

  connect() {
    return this.pusher.connect()
  }

  disconnect() {
    return this.pusher.disconnect()
  }

  subscribe(channelName: string) {
    return this.pusher.subscribe(channelName)
  }

  getPusher() {
    return this.pusher
  }
}

export const pusherManager = new PusherManager()
