export const APPCONFIGS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  VERIFY_EMAIL_REDIRECT: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  MIDDLEWARE_IP: process.env.REACT_APP_MIDDLEWARE_IP,
  PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
  PUSHER_SECRET: process.env.REACT_APP_PUSHER_SECRET,
  PUSHER_APP_ID: process.env.REACT_APP_PUSHER_APP_ID,
  PUSHER_USE_TLS: process.env.REACT_APP_PUSHER_USE_TLS,
  PUSHER_HOST: process.env.REACT_APP_PUSHER_HOST,
  PUSHER_PORT: process.env.REACT_APP_PUSHER_PORT,
  PUSHER_SCHEME: process.env.REACT_APP_PUSHER_SCHEME,
}
