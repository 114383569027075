import React, { Fragment, useEffect, useState } from 'react'

import styles from './course.module.css'

import { defaultImage } from '../../../../repository/assets'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import Swiper from '../../../organisms/swiper/Swiper.organism'
import { useAppSelector } from '../../../../lib/hooks'
import Tabs from '../../../molecules/tabs/Tabs.molecule'
import CourseCard from '../../../organisms/cards/course-card/CourseCard.organism'
import HomeCourseCard from '../../../organisms/cards/home-course-card/HomeCourseCard.organism'
import { categoriesData } from '../../../../repository/data/categories-data'
import { CourseEntityType } from '../../../../types/models'
import { IdName } from '../../../../types'
import { Link } from 'react-router-dom'
import { IconsRepository } from '../../../../repository/icons/icon.repository'

const Course: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0)

  const { courses, isLoading } = useAppSelector((state) => state.course)
  const [filteredCourses, setFilteredCourses] = useState<CourseEntityType[]>([])

  useEffect(() => {
    filterCourses()
  }, [activeTab, courses])

  const filterCourses = () => {
    if (courses?.length > 0) {
      const filters = [] as CourseEntityType[]

      for (let i = 0; i < courses.length; i++) {
        const cats: IdName[] = courses[i].categories as IdName[]
        for (let j = 0; j < cats.length; j++) {
          if (cats[j].name === categoriesData[activeTab]) {
            filters.push(courses[i])
          }
        }
      }

      setFilteredCourses(filters)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.courses}>
        <h2>Grow your skills with the best courses</h2>
        <div className={styles.tabs__container}>
          <div className={styles.avoidance}>
            <Tabs
              slideWidth='200px'
              labels={categoriesData}
              changeTab={setActiveTab}
              activeTab={activeTab}
              containerClassName={styles.tabContainer}
              tabClassName={styles.tab}
              activeClassName={styles.tabActive}
            />
          </div>
        </div>

        {isLoading ? (
          <Swiper singleSlideWidth={`${317}px`} gap='1.6rem'>
            {Array(16)
              .fill(0)
              .map((_course, index) => {
                return (
                  <Fragment key={index}>
                    <CourseCard theme='light' isLoading={true} cover={undefined} name={undefined} />
                  </Fragment>
                )
              })}
          </Swiper>
        ) : (
          ''
        )}

        {!isLoading && filteredCourses?.length > 0 ? (
          <Swiper singleSlideWidth={`${317}px`} gap='1.6rem'>
            {filteredCourses.map((item, index) => (
              <HomeCourseCard
                categories={item?.categories as IdName[]}
                key={index}
                img={item.cover?.url || ''}
                title={item?.name as string}
                id={`${item.id}`}
                stdCounts={'50'}
                students={[defaultImage, defaultImage, defaultImage]}
                desc={item?.description as string}
              />
            ))}
          </Swiper>
        ) : (
          <div className={styles.empty}>
            <IconsRepository.RocketLaunchIcon
              width={50}
              height={50}
              color='var(--platform-secondary-700)'
            />
            <h2>Coming Soon</h2>
          </div>
        )}

        <Link to='/course-category' className={styles.btn__styles}>
          <Button
            text='View All Categories'
            type={ButtonTypes.PRIMARY}
            fontSize='16px'
            height='59px'
            width='180px'
            className={styles.enroll__btn}
            // onClick={() => {
            //   navigate
            // }}
          />
        </Link>
      </div>
    </div>
  )
}

export default Course
