/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import Radio from '../../../molecules/radio/Radio.molecule'

import styles from './curriculum.module.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IdName } from '../../../../types'
import { enrollCourseThunk } from '../../../../features/course/thunks/course.thunk'
import Loader from '../../../atoms/loader/Loader'
import formatNumber from '../../../../lib/utils/formatNumber'

let isCurrentSubLessonFound = false
let nextSubLessonIsEnabled = false
const Curriculum: React.FC<{ openModal: () => void }> = ({ openModal }) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { singleCourse } = useAppSelector((state) => state.course)
  const { user, currentSubLesson, currentCourse } = useAppSelector((state) => state.authentication)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    isCurrentSubLessonFound = false
    nextSubLessonIsEnabled = false
  }, [])

  const findCurrentSubLesson = () => {
    const _course = user?.enrollments?.find((enrollment) => enrollment.course === singleCourse.id)
    if (!_course || !singleCourse || !singleCourse.lessons) {
      return [null, null]
    }

    for (let lesson of singleCourse.lessons!) {
      for (let sublesson of lesson.subLessons!) {
        console.log('currentSubLesson', currentSubLesson)
        console.log('sublesson.id', sublesson.id)
        if (sublesson.id === currentSubLesson) {
          return [lesson, sublesson]
        }
      }
    }

    console.log('Not Found??')
    return [null, null]
  }

  const [lesson, sublesson] = React.useMemo(findCurrentSubLesson, [
    currentSubLesson,
    currentCourse,
    singleCourse,
  ])

  const enrollCourse = async () => {
    if (user && id) {
      setLoading(true)
      const { email } = user
      await dispatch(enrollCourseThunk({ id, email })).then(() => {
        if (
          singleCourse.lessons !== undefined &&
          singleCourse.lessons.length > 0 &&
          singleCourse.lessons[0].subLessons !== undefined &&
          singleCourse.lessons[0].subLessons.length > 0
        ) {
          navigate(
            `/full-course/${singleCourse.id}/${singleCourse.lessons[0].id}/${singleCourse.lessons[0].subLessons[0].id}`,
          )
        }
      })
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <section className={styles.body}>
        <span className={styles.back} role='button' tabIndex={0} onClick={() => navigate(-1)}>
          <IconsRepository.ChevronLeftIcon size={16} />
        </span>
        <h3 className={styles.header}>Course Curriculum</h3>
        <div className={styles.nextLesson}>
          {lesson && sublesson ? (
            <Link to={`/full-course/${singleCourse.id}/${lesson.id}/${sublesson.id}`}>
              <Button
                type={ButtonTypes.PRIMARY}
                text={'Start Next Lesson'}
                fontSize='16px'
                height='32px'
                iconDir={IconDirection.RIGHT}
                icon={<IconsRepository.ChevronRight />}
                className={styles.custom}
              />
            </Link>
          ) : (
            <Button
              type={ButtonTypes.PRIMARY}
              text={`Enroll for ${formatNumber(singleCourse.price || 0)}frs `}
              fontSize='16px'
              height='32px'
              iconDir={IconDirection.RIGHT}
              onClick={openModal}
              icon={
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '-6px',
                  }}
                >
                  <IconsRepository.ChevronRightIcon />
                </span>
              }
              className={styles.custom}
            />
          )}

          <p>{sublesson?.name}</p>
        </div>
        {singleCourse.lessons !== undefined &&
          singleCourse.lessons.map((data, index) => {
            return (
              <Module
                {...data}
                topics={data.subLessons}
                chapter={data.name}
                index={index + 1}
                key={data.id}
                lId={data.id}
              />
            )
          })}
      </section>
    </>
  )
}

export default Curriculum

interface IModule {
  index: number
  topics?: IdName[]
  chapter?: string
  lId?: number
}

const Module: React.FC<IModule> = ({ chapter, topics, index, lId }) => {
  const { currentSubLesson } = useAppSelector((state) => state.authentication)
  const { singleCourse } = useAppSelector((state) => state.course)
  const { user } = useAppSelector((state) => state.authentication)
  const [subLessons, setSublessons] = useState<Array<any>>([])

  function getSubLessonStatus(subLessons: any[], currentSubLessonId: string): any[] {
    const _course = user?.enrollments?.find((enrollment) => enrollment.course === singleCourse.id)

    return subLessons?.map((subLesson) => {
      if (!_course) {
        return { isDisabled: true, isCurrent: false, ...subLesson }
      }

      let isDisabled = !nextSubLessonIsEnabled
      let isCurrent = subLesson.id === currentSubLessonId

      if (!currentSubLessonId) {
        isCurrentSubLessonFound = true
      }

      if (isCurrent) {
        isCurrentSubLessonFound = true
        nextSubLessonIsEnabled = true
        isDisabled = false
      } else {
        nextSubLessonIsEnabled = false
      }

      if (!isCurrentSubLessonFound) {
        isCurrent = true
        isDisabled = false
      }

      // isCurrent = isCurrentSubLessonFound ? isCurrent : true

      return { isDisabled, isCurrent, ...subLesson }
    })
  }

  useEffect(() => {
    const sublessons = getSubLessonStatus(topics || [], currentSubLesson || '')
    setSublessons(sublessons)
  }, [])

  // console.log('subLessons', subLessons)

  return (
    <section className={styles.module}>
      <h2 style={{ fontSize: '16px', fontWeight: 600 }}>{`Module ${index}. ${chapter}`}</h2>
      <div className={styles.topics}>
        {subLessons?.map((topic, index) => {
          return (
            <Topic
              topic={topic}
              lessonId={lId}
              index={index + 1}
              key={topic.id}
              isDisabled={topic.isDisabled}
              isCurrent={topic.isCurrent}
            />
          )
        })}
      </div>
    </section>
  )
}

const Topic: React.FC<{
  topic: IdName
  index: number
  lessonId?: number
  isDisabled?: boolean
  isCurrent?: boolean
}> = ({ topic, index, lessonId, isCurrent, isDisabled }) => {
  const { id } = useParams()
  const { user } = useAppSelector((state) => state.authentication)

  const isEnrolled: boolean = useMemo(() => {
    const course = user?.courses?.find((course) => course.courseId === id)
    if (!course) {
      return false
    }

    return true
  }, [])

  return (
    <div className={styles.topic}>
      <div className={styles.radioCont}>
        <Radio checked={isCurrent ? true : false} />
      </div>
      <div className={styles.tDetails}>
        <div>
          <IconsRepository.OndemanVideoIcon size={20} />
          <p>{`${index}. ${topic.name} `}</p>
        </div>
        {isDisabled ? (
          <Button
            type={ButtonTypes.PRIMARY}
            fontSize='12px'
            width='78px'
            height='31px'
            text={isEnrolled ? 'Start' : 'Follow'}
            className={styles.custom}
            disabled
          />
        ) : (
          <Link to={`/full-course/${id}/${lessonId}/${topic.id}${isCurrent ? '?repeat=true' : ''}`}>
            <Button
              type={ButtonTypes.PRIMARY}
              fontSize='12px'
              width='78px'
              height='31px'
              text={isCurrent ? 'Repeat' : 'Start'}
              className={styles.custom}
            />
          </Link>
        )}
      </div>
    </div>
  )
}
