import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// stylesheet
import styles from '../modules.module.css'

// general imports
import Toggle from '../../../../../components/molecules/toggle/Toggle.molecule'
import { appPaths } from '../../../../../constants/app-paths'
import { LocalStorage } from '../../../../../services/storage/Local.storage'
import { useAppDispatch } from '../../../../../lib/hooks'
import { resetAuthenticationState } from '../../../../../features/authentication/authenticationSlice'

const Account = () => {
  const [toggled, setToggled] = useState<boolean | number>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className={styles.module}>
      <div className={styles.head}>
        <h2>Logout</h2>
        <h3>Once you log out, there is no going back. Please be certain.</h3>
      </div>

      <div className={styles.logout}>
        <div className={styles.confirm}>
          <Toggle toggled={toggled} setToggleState={setToggled} />
          <div className={styles.helpers}>
            <h2>Confirm</h2>
            <h3>I want to logout</h3>
          </div>
        </div>
        <div className={styles.btn}>
          <button
            style={{
              cursor: !toggled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              dispatch(resetAuthenticationState())
              LocalStorage.removeCurrentUser()
              localStorage.removeItem('email_tracker')
              navigate(appPaths.LOGIN)
            }}
            disabled={!toggled as boolean}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default Account
