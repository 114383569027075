import React from 'react'
import { Quiz, UserQuiz } from '../../../../types'
import radiostyles from './radio.module.css'
import checkstyles from './checkbox.module.css'
import styles from './quiz.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import ProgressBar from '../../../organisms/progressbar/ProgressBar.organism'

interface QuizProps {
  quizzes: Array<Quiz>
  userQuizzes: Array<UserQuiz>
}

type StatusType = 'correct' | 'wrong' | ''

const CourseUserQuiz: React.FC<QuizProps> = ({ quizzes, userQuizzes }) => {
  const getQuizStatus = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }): { status: StatusType; remark: string } => {
    const userQuiz = userQuizzes.find((q) => q.quiz === quizID)
    if (!userQuiz) return { status: '', remark: '' }
    const progress = userQuiz.progress
    const questionProgress = progress.find((p) => p.question === questionID)
    const answer = questionProgress?.userAnswer.find((ans) => ans.id === answerID)

    if (!answer) {
      return { status: '', remark: '' }
    }

    return { status: answer.correct ? 'correct' : 'wrong', remark: answer.remark }
  }

  const getQuizScore = (quizID: number): number => {
    const userQuiz = userQuizzes.find((q) => q.quiz === quizID)
    if (!userQuiz) return 0
    return userQuiz.score
  }

  const isChecked = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const userQuiz = userQuizzes.find((q) => q.quiz === quizID)
    if (!userQuiz) return false
    const progress = userQuiz.progress
    const questionProgress = progress.find((p) => p.question === questionID)
    const answer = questionProgress?.userAnswer.find((ans) => ans.id === answerID)

    return Boolean(answer)
  }

  return (
    <section className={styles.body}>
      {quizzes.map((quiz) => {
        return (
          <React.Fragment key={`quiz_${quiz.id}`}>
            <LessonScore score={getQuizScore(quiz.id)} />
            {quiz.questions.map((question, index) => {
              if (question.type === 'single') {
                return (
                  <div key={`questions_${index}`} className={styles.question}>
                    <div className={styles.text}>
                      <span>Question {index + 1}:</span>
                      <h6>{question.text}</h6>
                    </div>
                    <hr className={styles.divider} />
                    {question.answers.map((answer) => {
                      const { status, remark } = getQuizStatus({
                        quizID: quiz.id,
                        questionID: question.id,
                        answerID: answer.id,
                      })
                      return (
                        <Radio
                          key={`answer_${answer.id}`}
                          name={question.text}
                          label={answer.value}
                          checked={isChecked({
                            quizID: quiz.id,
                            questionID: question.id,
                            answerID: answer.id,
                          })}
                          status={status}
                          remark={remark}
                        />
                      )
                    })}
                  </div>
                )
              } else {
                return (
                  <div key={`questions_${index}`} className={styles.question}>
                    <div className={styles.text}>
                      <span>Question {index + 1}:</span>
                      <h6>{question.text}</h6>
                    </div>
                    {question.answers.map((answer) => {
                      const { status, remark } = getQuizStatus({
                        quizID: quiz.id,
                        questionID: question.id,
                        answerID: answer.id,
                      })
                      return (
                        <CheckBox
                          key={`answer_${answer.id}`}
                          label={answer.value}
                          checked={isChecked({
                            quizID: quiz.id,
                            questionID: question.id,
                            answerID: answer.id,
                          })}
                          status={status}
                          remark={remark}
                        />
                      )
                    })}
                  </div>
                )
              }
            })}
          </React.Fragment>
        )
      })}
    </section>
  )
}

export default CourseUserQuiz

interface IRadioProps {
  name?: string
  label?: string
  checked: boolean
  status: StatusType
  remark: string
}

const Radio: React.FC<IRadioProps> = ({ label, name, checked, status, remark }) => {
  return (
    <div className={radiostyles.radio_wrapper}>
      <label className={radiostyles.label}>
        <input type={'radio'} onClick={(e) => e.stopPropagation()} name={name} />
        <span>{label}</span>
        <span className={radiostyles.circle}>
          <span
            className={`${radiostyles.dot} ${checked ? radiostyles.show : radiostyles.hide}`}
          ></span>
        </span>
      </label>
      {status === 'correct' ? (
        <div className={radiostyles.correct}>
          <div className={radiostyles.title}>
            <h6>Correct Answer</h6>
            <IconsRepository.CheckCircleIcon color='var(--camsol-status-success)' />
          </div>
          <div className={radiostyles.text}>
            <p>{remark}</p>
          </div>
        </div>
      ) : checked ? (
        <div className={radiostyles.wrong}>
          <div className={radiostyles.title}>
            <h6>Wrong Answer</h6>
            <IconsRepository.CancelCircleIcon color='var(--camsol-status-error)' />
          </div>
          <div className={radiostyles.text}>
            <span></span>
            <p>{remark}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

interface CheckboxProps {
  label?: string
  checked: boolean
  status: StatusType
  remark: string
}
const CheckBox: React.FC<CheckboxProps> = ({ label, checked, status, remark }) => {
  return (
    <div className={`${checkstyles.checkbox__wrapper}`}>
      <label className={checkstyles.label}>
        <span>{label}</span>
        <input type='checkbox' checked={checked} />
      </label>
      {status === 'correct' ? (
        <div className={checkstyles.correct}>
          <div className={checkstyles.title}>
            <h6>Correct Answer</h6>
            <IconsRepository.CheckCircleIcon color='var(--camsol-status-success)' />
          </div>
          <div className={checkstyles.text}>
            <p>{remark}</p>
          </div>
        </div>
      ) : checked ? (
        <div className={checkstyles.wrong}>
          <div className={checkstyles.title}>
            <h6>Wrong Answer</h6>
            <IconsRepository.CancelCircleIcon color='var(--camsol-status-error)' />
          </div>
          <div className={checkstyles.text}>
            <span></span>
            <p>{remark}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const LessonScore: React.FC<{ score: number }> = ({ score }) => {
  const percent = (score / 20) * 100
  return (
    <div className={styles.progressBody}>
      <div className={styles.title}>
        <IconsRepository.TrophyIcon color='var(--platform-secondary-700)' />
        <p>Lesson Quiz Score</p>
        <h3>{percent}%</h3>
      </div>
      <ProgressBar bgcolor='var(--platform-secondary-700)' completed={percent} noHeading />
    </div>
  )
}
