import React, { ReactNode } from 'react'
import styles from './modal.module.css'
import { createPortal } from 'react-dom'

interface ModalProps {
  children: ReactNode
  isOpen: boolean
  className?: string
  closeModal: () => void
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, className, closeModal }) => {
  return createPortal(
    <div
      onClick={closeModal}
      className={`${styles.body} ${isOpen ? styles.open : styles.closed} ${className}`}
    >
      {children}
    </div>,
    document.body,
  )
}

export default Modal
