import React from 'react'
import styles from './modals.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'

interface SuccessProps {
  startLesson: () => void
}

const Successmodal: React.FC<SuccessProps> = ({ startLesson }) => {
  return (
    <div className={styles.body} onClick={(e) => e.stopPropagation()}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <IconsRepository.TrophyIcon width={25} height={25} color='white' />
          <span className={styles.third}></span>
          <span className={styles.second}></span>
          <span className={styles.first}></span>
        </div>
        <div className={styles.text}>
          <h2>
            Payment <br /> Successful
          </h2>
          <p>
            You have successfully made the payment and will be enrolled into the course:{' '}
            <span style={{ color: 'var(--platform-secondary-700)' }}>UI/UX Bootcamp</span>
          </p>
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          fontSize='16px'
          width=' 360px'
          height='48px'
          text={'Start Lessons'}
          className={styles.btn2}
          onClick={startLesson}
        />
      </div>
    </div>
  )
}

export default Successmodal
