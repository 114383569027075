import React, { useRef } from 'react'

import styles from './meetInstructor.module.css'

import { courseBackground } from '../../../../repository/assets/videos'
import { InstructorEntityType } from '../../../../types/models'

const MeetInstructor: React.FC<InstructorEntityType> = ({ avatarUrl, name, type }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.meetInstructor}>
        <p className={styles.title}> Meet Your Instructor </p>
        <div className={styles.slideInfinite}>
          {[...Array(6)].map((_, i) => {
            return (
              <p className={styles.name} key={`${name}-${i}`}>
                {name}
              </p>
            )
          })}
        </div>
        <p className={styles.profession}> {type} </p>
        <VideoBox source={courseBackground} poster={avatarUrl || ''} />
      </div>
    </div>
  )
}

const VideoBox: React.FC<{ source: string; poster: string }> = ({ source, poster }) => {
  const vidRef = useRef<HTMLVideoElement | null>(null)
  // const [isPlaying, setPlaying] = useState(false)

  // const playPause = () => {
  //   if (isPlaying) {
  //     vidRef.current?.pause()
  //     setPlaying(false)
  //   } else {
  //     vidRef.current?.play()
  //     setPlaying(true)
  //   }
  // }

  return (
    <div className={styles.video}>
      <video ref={vidRef} poster={poster}>
        <source src={source} type='video/mp4' />
      </video>
      {/* <span className={isPlaying ? styles.hide : ''} onClick={playPause}>
        {isPlaying ? (
          <IconsRepository.PlayCircleFilledTwo size={65} />
        ) : (
          <IconsRepository.PlayCircleFilledTwo size={65} />
        )}
      </span> */}
    </div>
  )
}

export default MeetInstructor
