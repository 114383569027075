/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

// stylesheet
import styles from './fullcourse.module.css'

// general imports
import Intro from '../../components/templates/full-course/intro/Intro.template'
import Content from '../../components/templates/full-course/content/Content.template'
import { courseBackground } from '../../repository/assets/videos'
import axiosInstance from '../../lib/hooks/axios'
import Loader from '../../components/atoms/loader/Loader'
import SideNav from '../../components/templates/full-course/side-nav/SideBar.template'
import { lessonPlan } from '../../repository/data/lesson-plan-data'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { courseProgressThunk, singleCourseThunk } from '../../features/course/thunks/course.thunk'
import { IdName, Quiz, UserQuiz } from '../../types'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import { defaultImage } from '../../repository/assets'
import LessonNavigator from '../../components/molecules/lesson-navigator/LessonNavigator.molecule'
import DashboardFooter from '../../components/organisms/footer/DashboardFooter.organism'
import useWindowSize from '../../lib/hooks/useWindowSize'
import { UseScrollPosition } from '../../lib/hooks/useScrollPosition'
import { IconsRepository } from '../../repository/icons/icon.repository'
import { setCurrentCourse } from '../../features/authentication/authenticationSlice'
import StringContent from '../../components/templates/full-course/content/StringContent'
import { LocalStorage } from '../../services/storage/Local.storage'
import CourseQuiz from '../../components/templates/full-course/quiz/Quiz.template'
import CourseUserQuiz from '../../components/templates/full-course/user-quiz/UserQuiz.template'
import Modal from '../../components/organisms/modal/Modal.organism'
import AssessmentModal from '../../components/templates/full-course/modals/AssessmentModal.template'

type FetchedDataType = {
  name: string
  description: string
  cover: {
    url: string
  }

  // eslint-disable-next-line
  contents: any
  content: any
}

const combineUserQuizzes = (quizzes: Array<UserQuiz>) => {
  const _quizzes: Array<UserQuiz> = []
  for (let quiz of quizzes) {
    const existingQuizIndex = _quizzes.findIndex((q) => q.quiz === quiz.quiz)
    if (existingQuizIndex > -1) {
      const existingQuiz = _quizzes.at(existingQuizIndex)!
      existingQuiz.progress = existingQuiz.progress.concat(quiz.progress)
      existingQuiz.score = existingQuiz.score + quiz.score
      _quizzes[existingQuizIndex] = existingQuiz
    } else {
      _quizzes.push(quiz)
    }
  }

  return _quizzes
}

const FullCourse = () => {
  const { courseID, lessonID, subLessonID } = useParams()
  const [searchParams] = useSearchParams()
  const [fetchedData, setFetchedData] = useState<FetchedDataType>()
  const [quizzes, setQuizzes] = useState<Array<Quiz>>([])
  const [userQuizzes, setUserQuizzes] = useState<Array<UserQuiz>>([])
  const [loading, setLoading] = useState(false)
  const [toggleNavigator, setToggleNavigator] = useState(false)
  const { width } = useWindowSize()
  const position = UseScrollPosition()
  const { singleCourse } = useAppSelector((state) => state.course)
  const { user, currentCourse } = useAppSelector((state) => state.authentication)
  const dispatch = useAppDispatch()
  const navigator = useNavigate()

  const [lessonTracker, setLessonTracker] = useState<number>(0)
  const [subLessonTracker, setSubLessonTracker] = useState<number>(0)
  const [assessModalOpen, setAssessModalOpen] = useState(false)

  const fetchSubLessons = useCallback(
    async (lessonID: string, subLessonID: string, scroll: boolean = true) => {
      setLoading(true)

      try {
        const res = await axiosInstance.get(`/courses/sub-lessons/${subLessonID}`, {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        })
        setFetchedData(res.data?.subLesson)
        setQuizzes(res.data?.quizzes)
        setUserQuizzes(combineUserQuizzes(res.data?.userQuizzes))
        const email = user?.email
        if (email && courseID) {
          if (!searchParams.get('repeat')) {
            if (res.data?.quizzes.length === 0) {
              // No quiz, run course progress
              dispatch(courseProgressThunk({ email, courseID, lessonID, sublessonID: subLessonID }))
            }
          }
        }
        if (scroll) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [searchParams],
  )

  useEffect(() => {
    dispatch(singleCourseThunk(courseID as string))
    if (!currentCourse || courseID !== currentCourse) {
      dispatch(setCurrentCourse(courseID))
    }
  }, [])

  useEffect(() => {
    fetchSubLessons(lessonID as string, subLessonID as string)
  }, [fetchSubLessons, courseID, lessonID, subLessonID])

  const findAndSetTrackers = () => {
    let lesTracker = 0
    let subLesTracker = 0
    if (!singleCourse.lessons) {
      return
    }

    for (const idx in singleCourse.lessons) {
      const lesson = singleCourse.lessons[idx]
      if (lesson.id === Number(lessonID)) {
        lesTracker = Number(idx)
        for (const i in lesson.subLessons) {
          const sublesson = lesson.subLessons[Number(i)]
          if (sublesson.id === Number(subLessonID)) {
            subLesTracker = Number(i)
            break
          }
        }
        break
      }
    }

    setLessonTracker(lesTracker)
    setSubLessonTracker(subLesTracker)
  }

  useEffect(() => {
    findAndSetTrackers()
  }, [findAndSetTrackers, courseID, lessonID, subLessonID, singleCourse])

  return (
    <>
      <NavbarLoggedin padding='32px' userImage={defaultImage} />
      <div className={styles.full__course}>
        <div className={styles.toggler__wrapper}>
          <div onClick={() => setToggleNavigator(true)} className={styles.navigatortoggler}>
            {!toggleNavigator && (
              <span>
                <IconsRepository.NavMenuIcon />
              </span>
            )}
            <h3>Lesson navigator</h3>
          </div>

          {toggleNavigator && (
            <div onClick={() => setToggleNavigator(false)} className={styles.close__icon}>
              <IconsRepository.CloseIcon size={20} height={19} />
            </div>
          )}
        </div>
        {!toggleNavigator && (
          <LessonNavigator
            lessonTracker={lessonTracker}
            setSubLessonTracker={setSubLessonTracker}
            setLessonTracker={setLessonTracker}
            subLessonTracker={subLessonTracker}
            course={singleCourse}
            name={singleCourse?.name}
            hasQuiz={Boolean(quizzes.length)}
            hasUserQuiz={Boolean(userQuizzes.length)}
            openModal={() => setAssessModalOpen(true)}
          />
        )}
        <div
          style={{
            gap: width > 800 ? '32px' : '0px',
          }}
          className={styles.courses__section}
        >
          {loading ? <Loader /> : null}
          {!toggleNavigator && (
            <div className={styles.course__details}>
              <>
                <Intro
                  heading={fetchedData?.name as string}
                  description={fetchedData?.description as string}
                  vid={courseBackground}
                  cover={fetchedData?.cover?.url}
                />
                <StringContent content={fetchedData?.content} />
                {/* <Content content={fetchedData?.contents} /> */}
              </>
              <div style={{ marginBottom: '2.4rem' }}></div>

              {quizzes.length > 0 &&
                (userQuizzes.length > 0 ? (
                  <CourseUserQuiz quizzes={quizzes} userQuizzes={userQuizzes} />
                ) : (
                  <CourseQuiz
                    quizzes={quizzes}
                    refetch={() => {
                      dispatch(
                        courseProgressThunk({
                          email: user?.email!,
                          courseID: courseID!,
                          lessonID: lessonID!,
                          sublessonID: subLessonID!,
                        }),
                      )
                      fetchSubLessons(lessonID as string, subLessonID as string, false)
                    }}
                    startLoad={() => setLoading(true)}
                  />
                ))}
              <LessonNavigator
                full={true}
                lessonTracker={lessonTracker}
                setSubLessonTracker={setSubLessonTracker}
                setLessonTracker={setLessonTracker}
                subLessonTracker={subLessonTracker}
                course={singleCourse}
                name={singleCourse?.name}
                hasQuiz={Boolean(quizzes.length)}
                hasUserQuiz={Boolean(userQuizzes.length)}
                openModal={() => setAssessModalOpen(true)}
              />
            </div>
          )}
          {width > 800 || toggleNavigator ? (
            <div
              style={{
                height: '100%',
              }}
            >
              <div
                style={{
                  width: width > 800 ? '323px' : '100%',
                }}
                className={`${styles.more__display} ${
                  position > 100 && width > 800 ? styles.static : ''
                }`}
              >
                <SideNav
                  lessonTracker={lessonTracker}
                  setLessonTracker={setLessonTracker}
                  subLessonTracker={subLessonTracker}
                  setSubLessonTracker={setSubLessonTracker}
                  plan={lessonPlan}
                  lessons={singleCourse?.lessons as IdName[]}
                  courseID={courseID}
                  heading={singleCourse?.name}
                />
              </div>
              <div
                style={{
                  opacity: 0,
                  minHeight: width > 800 ? '250vh' : '0vh',
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, dolorem.
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal isOpen={assessModalOpen} closeModal={() => setAssessModalOpen(false)}>
        <AssessmentModal takeAssessment={() => setAssessModalOpen(false)} />
      </Modal>
      <DashboardFooter />
    </>
  )
}

export default FullCourse
