/**
 * @export reviewData
 */

import { defaultImage } from '../assets'

export interface dataTypes {
  description: string
  image: string
  name: string
  courseName: string
  review: number
}

const ReviewData: dataTypes[] = [
  {
    description:
      'The design courses offered by Camsol Training have exceeded my expectations. The materials are up-to-date and the instructors are experts in their field. Had the chance to go over most advanced UX concepts and generally improve my design skills with the knowledge acquired.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 4.5,
  },
  {
    description:
      'Camsol Training is indeed a great platform. Has a user-friendly interface and lots of features for managing courses and student progress. This platform has revolutionized the way I approach learning - the interactive features make the experience engaging and FUN',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The content of the UI/UX Bootcamp is comprehensive and well-structured, with real-life examples and practical exercises. I learned a lot and it helped me get a job as a data analyst.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 5,
  },
  {
    description:
      'The strategic management course is a great course for those who seek to have a better understanding of how to manage a team and drive team members to optimal productivity.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'As someone who struggles with traditional classroom settings, this platform has been a godsend. The multimedia approach has helped me retain so much more information',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'I appreciate the emphasis on practical skills - the lessons are relevant to the real world and I feel confident applying what I have learned. Having access to this courses for free is a game changer for me, I have the opportunity to learn from the best without spending a lot.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The community aspect of this platform is invaluable - I have connected with learners all over the world and we have been able to collaborate and support each other in our studies',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'I have been able to apply what I have learned on this platform to my job and have noticed a significant improvement in my performance.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. The course was engaging and interactive giving me the opportunity to practice what I had learnt in real life. Overall, I generally recommend this course for everyone learning coding.',
    image: defaultImage,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
]

export default ReviewData
