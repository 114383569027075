import Pusher, { Channel } from 'pusher-js'
import { useEffect, useRef } from 'react'
import { pusherManager } from '../utils/pusher'
import { PUSHER_CHANNELS, PUSHER_EVENTS } from '../../constants/configs'

type UsePusherProps = {
  channel: PUSHER_CHANNELS
  event: PUSHER_EVENTS
  listener: (data: unknown, metadata: unknown) => void
  userId?: string
  type?: string
}

const usePusher = ({ channel, event, listener, userId, type = 'public' }: UsePusherProps) => {
  const pusherRef = useRef<Pusher | null>(null)
  const pusherChannelRef = useRef<Channel | null>(null)

  const eventListenerId = `${event}-${channel}-${userId}`

  useEffect(() => {
    const pusher: Pusher = pusherManager.getPusher()

    pusher.connection.bind('state_change', function (states: { current: string }) {
      if (states.current === 'connected') {
        console.log(`=> Pusher ${channel} ==> Connected `)
      }
    })

    let pusherChannel: Channel
    pusherManager.connect()

    if (type === 'public') {
      pusherChannel = pusherManager.subscribe(channel)

      pusherChannel.bind(event, listener)
      pusherChannelRef.current = pusherChannel
    }

    if (type == 'private') {
      const privateChannelId = `${channel}-${userId}`
      pusherChannel = pusher.subscribe(privateChannelId)
      pusherChannel.bind(event, listener)
      pusherChannelRef.current = pusherChannel
    }

    // Store the pusher and pusherChannel instances in refs
    pusherRef.current = pusher

    // Return a function that does nothing to leave the subscription open
    return () => {
      if (pusherRef.current && pusherChannelRef.current) {
        pusherRef.current.unsubscribe(pusherChannelRef.current.name)
      }
    }
  }, [eventListenerId])

  // You can expose a function to manually unsubscribe from the channel
  const unsubscribe = () => {
    if (pusherRef.current && pusherChannelRef.current) {
      pusherRef.current.unsubscribe(pusherChannelRef.current.name)
    }
  }

  return { unsubscribe }
}

export default usePusher
