import React from 'react'
import styles from './modals.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import CustomInputField from '../../../molecules/input/CustomInput.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import useWindowSize from '../../../../lib/hooks/useWindowSize'

interface PaymentModalProps {
  closeModal: () => void
  next: () => void
  formData: {
    phone: string
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      phone: string
    }>
  >
}

const Paymentmodal: React.FC<PaymentModalProps> = ({ closeModal, next, formData, setFormData }) => {
  const { width } = useWindowSize()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const isDisabled = () => {
    return formData.phone?.length !== 9
  }

  return (
    <div className={styles.body} onClick={(e) => e.stopPropagation()}>
      <span className={styles.close} onClick={closeModal}>
        <IconsRepository.CloseIcon width={19} height={19} />
      </span>
      <div className={styles.content}>
        <div className={styles.text}>
          <h2>Checkout</h2>
          <p>Enter MTN mobile money and payer message to cash out with MTN mobile money.</p>
        </div>
        <div className={styles.input}>
          <span>Phone Number:</span>
          <CustomInputField
            name='phone'
            type='number'
            value={formData.phone}
            onChange={onChange}
            placeholder='677150086'
            width={width < 768 ? '100%' : '360px'}
          />
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          fontSize='16px'
          width=' 360px'
          height='48px'
          text={'Continue'}
          className={styles.btn2}
          disabled={isDisabled()}
          onClick={next}
        />
      </div>
    </div>
  )
}

export default Paymentmodal
