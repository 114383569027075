import React from 'react'
import { IconProps } from '../types'

const WarningIcon = ({ size = 30, stroke = 2, color = '#5c187a', ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.25 27.375H28.75L15 3.625L1.25 27.375ZM16.25 23.625H13.75V21.125H16.25V23.625ZM16.25 18.625H13.75V13.625H16.25V18.625Z'
        fill={color}
      />
    </svg>
  )
}

export default WarningIcon
