import React from 'react'
import styles from './modals.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'

interface SuccessProps {
  takeAssessment: () => void
}

const AssessmentModal: React.FC<SuccessProps> = ({ takeAssessment }) => {
  return (
    <div className={styles.body} onClick={(e) => e.stopPropagation()}>
      <span className={styles.close} onClick={takeAssessment}>
        <IconsRepository.CloseIcon width={19} height={19} />
      </span>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <IconsRepository.WarningIcon />
        </div>
        <div className={styles.text}>
          <h2>Complete Lesson Assessment</h2>
          <p>
            You have to complete the lesson Assessment for this lesson in order to move to the next
            lesson.
          </p>
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          fontSize='16px'
          width=' 360px'
          height='48px'
          text={'Take Assesssment'}
          className={styles.btn2}
          onClick={takeAssessment}
        />
      </div>
    </div>
  )
}

export default AssessmentModal
